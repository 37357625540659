<template>
  <v-layout v-if="getPermission('product:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Product Information
              </h3>
            </v-card-title>

            <v-card-text class="p-6 font-size-16">
              <v-col md="12" class="py-0">
                <div
                  class="middle-header-background mt-4 mt-7 px-4 d-flex align-center"
                >
                  <p>
                    <span>
                      <v-icon small>mdi-progress-question</v-icon> Specify the
                      seller, seller code and price details for your
                      product</span
                    >
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!productCreate.suppliers.length"
                    class="white--text mr-2"
                    depressed
                    color="cyan"
                    tile
                    @click="addSupplier()"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add Supplier
                    <!-- :disabled="isDisable" -->
                  </v-btn>
                </div>
              </v-col>

              <v-col md="12">
                <v-row
                  class="mt-1"
                  v-if="
                    productCreate &&
                    productCreate.suppliers &&
                    productCreate.suppliers.length
                  "
                >
                  <v-col md="6" class="py-0">
                    <label for="supplier fw-500" class="btx-label mt-2 required"
                      >Seller
                      <!-- <pre>{{ vendorList }}</pre> -->
                    </label>
                  </v-col>
                  <v-col md="3" class="py-0" v-if="false">
                    <label for="supplier-part-number" class="btx-label mt-2"
                      >Seller Code</label
                    >
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label for="supplier-price" class="btx-label mt-2"
                      >Purchasing Price</label
                    >
                  </v-col>
                  <v-col class="py-0">
                    <label for="" class="btx-label mt-2"></label>
                  </v-col>
                </v-row>
                <template>
                  <v-row
                    v-for="(row, index) in productCreate.suppliers"
                    :key="index"
                    class="mb-3"
                  >
                    <v-col md="6" class="py-0">
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="
                          supplierList(
                            productCreate.suppliers[index].supplier_id
                          )
                        "
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier"
                        placeholder="Seller"
                        v-model="productCreate.suppliers[index].supplier_id"
                        :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_id,
                            'Seller'
                          ),
                        ]"
                        class="mt-0"
                      >
                        <!-- @change="saveSupplierToLocal()" -->
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Seller Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3" class="py-0" v-if="false">
                      <v-text-field
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-part-number"
                        placeholder="Seller Code"
                        v-model="productCreate.suppliers[index].part_number"
                        class="mt-0"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" class="py-0">
                      <!-- :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_price,
                            'Purchasing Price'
                          ),
                        ]" -->
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-price"
                        placeholder="Purchasing Price"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="productCreate.suppliers[index].supplier_price"
                        class="mt-0"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" class="py-0" v-if="false">
                      <v-btn
                        class="white--text"
                        depressed
                        color="red darken-4"
                        tile
                        @click="removeSupplier(index, row)"
                        min-width="30"
                        max-width="30"
                        width="30"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-card-text>

            <v-card-text class="p-6 font-size-16">
              <div class="d-flex" v-if="false">
                <label
                  class="pr-2 font-weight-700 required font-size-16 width-100"
                  >Seller</label
                >
              </div>
              <div class="d-flex" v-if="false">
                <v-autocomplete
                  hide-details
                  solo
                  flat
                  dense
                  :items="vendorList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="supplier"
                  placeholder="Seller"
                  v-model="productCreate.seller"
                  :rules="[
                    validateRules.required(productCreate.seller, 'Seller'),
                  ]"
                  class="mt-0"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Seller(S) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <!-- <TextInput
                  placeholder="Seller"
                  class="mb-3"
                  :hideTopMargin="true"
                  hideDetails
                  v-model.trim="productCreate.name"
                  :rules="[
                    validateRules.required(productCreate.name, 'Seller'),
                    validateRules.minLength(productCreate.name, 'Seller', 1),
                    validateRules.maxLength(productCreate.name, 'Seller', 100),
                  ]"
                  :suffix="`${
                    productCreate.name ? productCreate.name.length : 0
                  }/50`"
                ></TextInput> -->
              </div>

              <div class="d-flex">
                <label
                  class="font-weight-700 font-size-16 width-100"
                  v-if="false"
                  >Reference Number</label
                >
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Condition
                </label>
                <label class="pr-2 font-weight-700 font-size-16 pl-2 width-100"
                  >Series</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  v-if="false"
                  dense
                  filled
                  placeholder="Reference Number"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.ref_number"
                ></v-text-field>
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="categoryList"
                  placeholder="Condition"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="manageCategoryDialog = true"
                  item-text="text"
                  item-value="text"
                  class="width-100"
                  v-model.trim="productCreate.category"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Condition(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Series"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="productCreate.item_number"
                ></v-text-field>
              </div>
              <div class="d-flex" v-if="false">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Part Number</label
                >
                <!-- <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  for="serial_number"
                  >Serial Number</label
                > -->
              </div>
              <div class="d-flex" v-if="false">
                <v-text-field
                  v-if="false"
                  dense
                  filled
                  placeholder="Part Number"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.part_number,
                      'Part Number',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.part_number,
                      'Part Number',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.part_number"
                ></v-text-field>
                <!--    <v-text-field
                  id="serial_number"
                  dense
                  filled
                  placeholder="Serial Number"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.serial_number"
                ></v-text-field> -->
                <!--  <pre>{{ categoryList }}</pre> -->
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Brand</label
                >
                <label class="font-weight-700 font-size-16 width-100 pl-2"
                  >Class</label
                >
              </div>
              <div class="d-flex">
                <v-autocomplete
                  :items="brandList"
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  placeholder="Brand"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="BrandData"
                  item-text="text"
                  item-value="text"
                  class="width-100"
                  v-model.trim="productCreate.brand"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Brand(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  dense
                  filled
                  placeholder="Class"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.website_url"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Model Code 1</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Model Code 2
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Model Code 1"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.model,
                      'Model Code 1',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.model,
                      'Model Code 1',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.model"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Model Code 2"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.model_no,
                      'Model Code 2',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.model_no,
                      'Model Code 2',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.model_no"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Model Type</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Mast Height (mm)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Model Type"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.model_type,
                      'Model Type',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.model_type,
                      'Model Type',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.model_type"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Mast Height (mm)"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.mast_height,
                      'Mast Height (mm)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.mast_height,
                      'Mast Height (mm)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.mast_height"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Tires</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >ATT
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Tires"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(productCreate.tires, 'Tires', 1),
                    validateRules.maxLength(productCreate.tires, 'Tires', 100),
                  ]"
                  v-model.trim="productCreate.tires"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="ATT"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(productCreate.att, 'ATT', 1),
                    validateRules.maxLength(productCreate.att, 'ATT', 100),
                  ]"
                  v-model.trim="productCreate.att"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Engine</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Power Source
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Engine"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(productCreate.engine, 'Engine', 1),
                    validateRules.maxLength(
                      productCreate.engine,
                      'Engine',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.engine"
                ></v-text-field>

                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="pscategoryList"
                  placeholder="Power Source"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="managePSCategoryDialog = true"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.power_source"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Power Source(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Battery voltage (V)</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Battery Capacity (Ah)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Battery voltage (V)"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.battery_voltage,
                      'Battery voltage (V)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.battery_voltage,
                      'Battery voltage (V)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.battery_voltage"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Battery Capacity (Ah)"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.battery_capacity,
                      'Battery Capacity (Ah)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.battery_capacity,
                      'Battery Capacity (Ah)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.battery_capacity"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Delivery Terms (days)</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Factory
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Delivery Terms (days)"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.delivery_terms,
                      'Delivery Terms (days)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.delivery_terms,
                      'Delivery Terms (days)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.delivery_terms"
                ></v-text-field>

                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="factcategoryList"
                  placeholder="Factory"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="managefactCategoryDialog = true"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.factory"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Factory(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Warranty (truck)</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Warranty (li-ion battery)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Warranty (truck)"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.warranty_truck,
                      'Warranty (truck)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.warranty_truck,
                      'Warranty (truck)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.warranty_truck"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Warranty (li-ion battery)"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.warranty_li_ion_battery,
                      'Warranty (li-ion battery)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.warranty_li_ion_battery,
                      'Battery Capacity (Ah)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.warranty_li_ion_battery"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Country of quote</label
                >
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Quote date
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Country"
                  solo
                  flat
                  color="cyan"
                  class=""
                  v-model.trim="productCreate.country_quote"
                ></v-text-field>

                <DatePicker
                  class="pl-2 ml-2"
                  solo
                  flat
                  :mandatory="false"
                  :nullable="true"
                  placeholder="Quote date"
                  v-model="productCreate.purchase_date"
                  :default-date="productCreate.purchase_date"
                ></DatePicker>
              </div>
            </v-card-text>
          </v-card>
          <br />
          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Cost Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="text-end">
                <v-btn
                  depressed
                  @click="addCostSupplier()"
                  class="white--text"
                  color="cyan"
                  ><v-icon small color="white">mdi-plus</v-icon>Add</v-btn
                >
              </div>
              <template v-for="(row, index) in productCreate.costSupplierData">
                <div :key="index">
                  <div class="d-flex">
                    <label
                      class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                      >Selling Price</label
                    >
                    <label
                      class="pr-5 pl-2 font-weight-700 font-size-16 width-100"
                      >Supplier Name
                    </label>
                    <label
                      class="pr-5 pl-1 font-weight-700 font-size-16 width-100"
                      >Purchase Price
                    </label>
                    <label class="pr-5 pl-5 font-weight-700"> </label>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      placeholder="Selling Price"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      v-model.trim="row.selling_price"
                    ></v-text-field>

                    <v-autocomplete
                      :items="supplierNameList"
                      dense
                      filled
                      placeholder="Supplier Name"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      v-model.trim="row.supplier_name"
                    ></v-autocomplete>
                    <v-text-field
                      dense
                      filled
                      placeholder="Purchase Price"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      v-model.trim="row.purchase_price"
                    ></v-text-field>
                    <v-btn
                      depressed
                      class="pr-2"
                      @click="removeCostSupplier(index)"
                      color="red"
                      ><v-icon color="white">mdi-delete</v-icon></v-btn
                    >
                  </div>
                </div>
              </template>
            </v-card-text>
          </v-card>
          <br />
          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Description Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="font-weight-700 font-size-16">Description</label>
              <v-textarea
                class="pb-0"
                hide-details
                dense
                filled
                placeholder="Description"
                solo
                flat
                row-height="20"
                auto-grow
                color="cyan"
                v-model.trim="productCreate.description"
                :rules="[
                  validateRules.minLength(
                    productCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.description,
                    'Description',
                    1024
                  ),
                ]"
                v-on:keypress="(e) => manageLimitdescr(e)"
                v-on:paste="(e) => onPaste(e)"
              ></v-textarea>
              <v-col md="12" class="text-right py-0"
                >{{
                  productCreate.description
                    ? productCreate.description.length
                    : 0
                }}/200</v-col
              >
            </v-card-text>
          </v-card>
          <br />

          <!-- New -->
          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-col md="12" class="py-0">
                <div
                  class="middle-header-background mt-4 mt-7 px-4 d-flex align-center"
                >
                  <p>
                    Price Information<br />
                    <span>
                      <v-icon small>mdi-progress-question</v-icon> Specify the
                      unit price, selling cost and discount price details for
                      your product</span
                    >
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="white--text mr-2"
                    depressed
                    color="cyan"
                    tile
                    @click="addPrice()"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add New Price
                    <!-- :disabled="isDisable" -->
                  </v-btn>
                </div>
              </v-col>

              <!-- v-if="!uuid && priceList && priceList.length" -->
              <v-col
                md="12"
                v-if="
                  productCreate &&
                  productCreate.priceList &&
                  productCreate.priceList.length
                "
              >
                <v-row class="mt-1">
                  <v-col class="py-0">
                    <label class="btx-label mt-2 required"
                      >Selling Price/Unit Price</label
                    >
                  </v-col>
                  <v-col class="py-0">
                    <label class="btx-label mt-2">Discounted Price</label>
                  </v-col>
                  <v-col class="py-0">
                    <label class="btx-label mt-2">Start Date</label>
                  </v-col>
                  <v-col class="py-0">
                    <label class="btx-label mt-2">End Date</label>
                  </v-col>
                  <v-col md="1" class="py-0">
                    <label class="btx-label mt-2"></label>
                  </v-col>
                </v-row>
                <template>
                  <v-row
                    v-for="(row, index) in productCreate.priceList"
                    :key="index"
                    class="mb-3"
                  >
                    <v-col class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        solo
                        flat
                        dense
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        placeholder="Selling Price/Unit Price"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="row.selling_cost"
                        @keyup="discountPriceChange(index)"
                        v-on:change="discountSelling(index)"
                        :rules="[
                          validateRules.required(
                            row.selling_cost,
                            'Selling Price/Unit Price'
                          ),
                        ]"
                        class="mt-0"
                        hide-spin-buttons
                      ></v-text-field>

                      <!-- dense
                              filled
                              color="cyan"
                              placeholder="Selling Cost / Unit Cost"
                              solo
                              flat
                              class="pl-2 width-100"
                              :class="{ 'pr-2': !updateMode }"
                              type="number"
                              min="0"
                              max="9999"
                              v-on:keypress="
                                (e) => manageLimit(e, row.selling_cost)
                              "
                              prepend-inner-icon="mdi-currency-usd"
                              v-model.trim="row.selling_cost" -->
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        placeholder="Discount Price"
                        @keyup="discountPriceChange(index)"
                        v-on:change="discountSelling(index)"
                        hide-spin-buttons
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.number="row.discount_price"
                        class="mt-0"
                      ></v-text-field>

                      <!-- dense
                                filled
                                color="cyan"
                                placeholder="Discounted Price"
                                solo
                                flat
                                class="pr-2 width-50"
                                type="number"
                                min="0"
                                max="5"
                                prepend-inner-icon="mdi-currency-usd"
                                v-model.trim="row.discount_price"
                                v-on:keypress="
                                  (e) => manageLimit(e, row.discount_price)
                                " -->
                    </v-col>
                    <v-col class="py-0">
                      <DatePicker
                        hide-details
                        hide-top-margin
                        :disabled="isStartDateDisable(index) || pageLoading"
                        :loading="pageLoading"
                        placeholder="Start Date"
                        :min-date="end_min_date(row.start_date, index)"
                        v-model="row.start_date"
                        :rules="[
                          validateRules.required(row.start_date, 'Start Date'),
                        ]"
                      >
                        <!-- outlined
                            depressed
                            hide-details
                            placeholder="Start Date"
                            v-model.trim="row.start_date"
                            class-name="mt-0"
                            clearable -->
                      </DatePicker>
                      <!-- <DatePicker2
														hide-details
														hide-top-margin
														:disabled="isStartDateDisable(index) || pageLoading"
														:loading="pageLoading"
														placeholder="Start Date"
														:min-date="end_min_date(row.start_date, index)"
														v-model="row.start_date"
														:rules="[vrules.required(row.start_date, 'Start Date')]"
														:class="{
															required: !row.start_date,
														}"
														@change="updateStartDate(row.start_date, index)"
													></DatePicker2> -->
                    </v-col>
                    <v-col class="py-0">
                      <DatePicker
                        hide-details
                        readonly
                        :loading="pageLoading"
                        :disabled="true"
                        hide-top-margin
                        placeholder="End Date"
                        v-model="row.end_date"
                      >
                        <!-- outlined
                            depressed
                            hide-details
                            placeholder="End Date"
                             v-model.trim="row.end_date"
                            class-name="mt-0"
                            clearable -->
                      </DatePicker>

                      <!-- <DatePicker2
														hide-details
														readonly
														:loading="pageLoading"
														:disabled="true"
														hide-top-margin
														placeholder="End Date"
														v-model="row.end_date"
													></DatePicker2> -->
                    </v-col>
                    <v-col md="1" class="py-0">
                      <v-btn
                        v-if="productCreate.priceList.length == index + 1"
                        class="white--text"
                        depressed
                        color="red darken-4"
                        tile
                        @click="removePrice(index, row)"
                        min-width="30"
                        max-width="30"
                        width="30"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-card-text>
          </v-card>
          <br />
          <!-- New End -->

          <!-- supplier start -->
          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Seller Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-col md="12" class="py-0">
                <div
                  class="middle-header-background mt-4 mt-7 px-4 d-flex align-center"
                >
                  <p>
                    <span style="margin-left: -10px">
                      <v-icon small>mdi-progress-question</v-icon> Specify the
                      seller, seller code and price details for your
                      product</span
                    >
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!productCreate.suppliers.length"
                    class="white--text mr-2"
                    depressed
                    color="cyan"
                    tile
                    @click="addSupplier()"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add Supplier
                    <!-- :disabled="isDisable" -->
                  </v-btn>
                </div>
              </v-col>

              <v-col md="12">
                <v-row
                  class="mt-1"
                  v-if="
                    productCreate &&
                    productCreate.suppliers &&
                    productCreate.suppliers.length
                  "
                >
                  <v-col md="5" class="py-0">
                    <label for="supplier fw-500" class="btx-label mt-2 required"
                      >Seller
                      <!-- <pre>{{ vendorList }}</pre> -->
                    </label>
                  </v-col>
                  <v-col md="3" class="py-0" v-if="false">
                    <label for="supplier-part-number" class="btx-label mt-2"
                      >Seller Code</label
                    >
                  </v-col>
                  <v-col md="3" class="py-0">
                    <label for="supplier-price" class="btx-label mt-2 required"
                      >Purchasing Price</label
                    >
                  </v-col>
                  <v-col class="py-0">
                    <label for="" class="btx-label mt-2"></label>
                  </v-col>
                </v-row>
                <template>
                  <v-row
                    v-for="(row, index) in productCreate.suppliers"
                    :key="index"
                    class="mb-3"
                  >
                    <v-col md="5" class="py-0">
                      <!-- <v-text-field
                           type="number"
														outlined
                            dense 
														:disabled="pageLoading"
														:loading="pageLoading"
														placeholder="Selling Price/Unit Price"
														prepend-inner-icon="mdi-currency-usd"
														v-model="row.selling_cost" 
														:rules="[
                            validateRules.required(row.selling_cost, 'Selling Price/Unit Price'),
                          ]"
													
														class="mt-0"
                           ></v-text-field> -->
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="
                          supplierList(
                            productCreate.suppliers[index].supplier_id
                          )
                        "
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier"
                        placeholder="Seller"
                        v-model="productCreate.suppliers[index].supplier_id"
                        :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_id,
                            'Seller'
                          ),
                        ]"
                        class="mt-0"
                      >
                        <!-- @change="saveSupplierToLocal()" -->
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Seller Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3" class="py-0" v-if="false">
                      <v-text-field
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-part-number"
                        placeholder="Seller Code"
                        v-model="productCreate.suppliers[index].part_number"
                        class="mt-0"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-price"
                        placeholder="Purchasing Price"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="productCreate.suppliers[index].supplier_price"
                        :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_price,
                            'Purchasing Price'
                          ),
                        ]"
                        class="mt-0"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>

                    <!-- :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_price,
                            'Purchasing Price'
                          ),
                        ]" -->
                    <!-- <v-col class="py-0">
                          <DatePicker
                           hide-details
														readonly
														:loading="pageLoading"
														:disabled="true"
														hide-top-margin
														placeholder="End Date"
														v-model="row.end_date"
                            
                          >
                          
                          </DatePicker>

												</v-col> -->
                    <v-col md="1" class="py-0" v-if="false">
                      <v-btn
                        class="white--text"
                        depressed
                        color="red darken-4"
                        tile
                        @click="removeSupplier(index, row)"
                        min-width="30"
                        max-width="30"
                        width="30"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-card-text>
          </v-card>
          <!-- supplier end -->

          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing / Quantity Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Selling Cost / Unit Cost</label
                >

                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Threshold Price</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Selling Cost / Unit Cost"
                  solo
                  flat
                  class="pl-2 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.selling_cost)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.selling_cost"
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Threshold Price"
                  solo
                  flat
                  class="pl-2 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.threshold_price)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.threshold_price"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Cost Price</label
                >

                <!-- <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Quantity</label
                > -->
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Cost Price"
                  solo
                  flat
                  class="pr-2 width-50"
                  type="number"
                  min="0"
                  max="5"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.purchase_cost"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.purchase_cost)
                  "
                ></v-text-field>
                <!--  <v-text-field
                    placeholder="Quantity"
                    dense
                    filled
                    solo
                    flat
                    class="pl-2 width-100"
                    type="number"
                    color="cyan"
                    v-model="productCreate.quantity"
                    v-mask="'####'"
                  ></v-text-field> -->
              </div>

              <div v-if="false" class="d-flex w-50">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Commission Rate (%)</label
                >
              </div>
              <div v-if="false" class="d-flex w-50">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Commission Rate (%)"
                  solo
                  flat
                  class="pr-2 width-100"
                  type="number"
                  min="0"
                  max="9999"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.commission_rate"
                  v-on:keypress="
                    limitDecimal($event, productCreate.commission_rate)
                  "
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Stock Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pl-2 font-weight-700 font-size-16 width-50 required"
                  >UOM
                </label>
                <label
                  class="pl-9 font-weight-700 font-size-16 width-50 required"
                  >Initial Quantity</label
                >
                <!--   <label class="font-weight-700 font-size-16 width-50"
                  >Purchase Date</label
                > -->
              </div>
              <div class="d-flex">
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="unitList"
                  placeholder="UOM"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="UomData"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-33"
                  v-model.trim="productCreate.unit"
                  :rules="[validateRules.required(productCreate.unit, 'UOM')]"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No UOM Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Initial Quantity"
                  solo
                  flat
                  :rules="
                    productCreate.product_type == 'goods'
                      ? [
                          validateRules.required(
                            productCreate.initial_stock,
                            'Initial Quantity'
                          ),
                        ]
                      : []
                  "
                  v-mask="'######'"
                  class="pl-2 width-33"
                  v-model.trim="productCreate.initial_stock"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <!-- Specs Info-->
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                SPECS Info
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Length to Fork Face (mm)
                </label>
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Overall Width (mm)</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Length to Fork Face (mm)"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.length_to_fork"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Overall Width (mm)"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="productCreate.overall_width"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Turning radius (mm)</label
                >
                <label class="font-weight-700 font-size-16 width-100 pl-1"
                  >Ground Clearance [wheelbase center] (mm)</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Turning radius"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.turning_radius"
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  placeholder="Ground Clearance"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.ground_clearance"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Travel Speed [lad/unlad] (mm)
                </label>
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Lift Speed [lad/unlad] (mm)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Travel Speed"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.travel_speed,
                      'Travel Speed',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.travel_speed,
                      'Travel Speed',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.travel_speed"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Lift Speed"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.lift_speed,
                      'Lift Speed',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.lift_speed,
                      'Lift Speed',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.lift_speed"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Max gradeability [lad/unlad] (mm)
                </label>
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Drive Motor (kW)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Max gradeability"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.max_grade,
                      'Max gradeability',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.max_grade,
                      'Max gradeability',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.max_grade"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Drive Motor"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.drive_medium,
                      'Drive Motor',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.drive_medium,
                      'Drive Motor',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.drive_medium"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Hydraulic Motor (kW)</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Power (kW/rpm)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Hydraulic Motor"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.hydraulic,
                      'Hydraulic Motor',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.hydraulic,
                      'Hydraulic Motor',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.hydraulic"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Power"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(productCreate.power, 'Power', 1),
                    validateRules.maxLength(productCreate.power, 'Power', 100),
                  ]"
                  v-model.trim="productCreate.power"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Torque (Nm/rpm)</label
                >
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Noise level (dB)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Torque"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(productCreate.torque, 'Torque', 1),
                    validateRules.maxLength(
                      productCreate.torque,
                      'Torque',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.torque"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Noise level"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.noise_level,
                      'Noise level',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.noise_level,
                      'Noise level',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.noise_level"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-0 font-weight-700 font-size-16 width-100"
                  >Entry date
                </label>
              </div>
              <div class="d-flex">
                <DatePicker
                  class="pl-2 width-33"
                  solo
                  flat
                  :mandatory="false"
                  :nullable="true"
                  placeholder="Entry date"
                  v-model="productCreate.entry_date"
                  :default-date="productCreate.entry_date"
                ></DatePicker>
              </div>
            </v-card-text>
          </v-card>
          <!-- Sepcs Info-->
          <br /><br />
          <!-- Pricing Info-->
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing Info
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Local Currency
                </label>
                <label class="pr-2 font-weight-700 pl-2 font-size-16 width-100"
                  >Local Selling Price</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Local Currency"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.local_currency"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  color="cyan"
                  placeholder="Local Selling Price"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="productCreate.local_selling_price"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Local Selling Price (FX)</label
                >
                <label class="font-weight-700 font-size-16 width-100 pl-2"
                  >Local Selling Price (JPY)</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Local Selling Price (FX)"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.local_selling_price_fx"
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  placeholder="Local Selling Price (JPY)"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.local_selling_price_jpy"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Import/DN Currency
                </label>
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Import/DN Price
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Import/DN Currency"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.import_dn_currency,
                      'Import/DN Currency',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.import_dn_currency,
                      'Import/DN Currency',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.import_dn_currency"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Import/DN Price"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.import_dn_price,
                      'Import/DN Price',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.import_dn_price,
                      'Import/DN Price',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.import_dn_price"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 font-weight-700 font-size-16 width-100"
                  >Import/DN Price (FX)
                </label>
                <label class="pr-2 pl-3 font-weight-700 font-size-16 width-100"
                  >Import/DN Price (JPY)
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  placeholder="Import/DN Price (FX)"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.import_dn_price_fx,
                      'Import/DN Price (FX)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.import_dn_price_fx,
                      'Import/DN Price (FX)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.import_dn_price_fx"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  placeholder="Import/DN Price (JPY)"
                  solo
                  flat
                  color="cyan"
                  class="pl-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.import_dn_price_jpy,
                      'Import/DN Price (JPY)',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.import_dn_price_jpy,
                      'Import/DN Price (JPY)',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.import_dn_price_jpy"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>
          <!-- Pricing Info-->

          <!-- Custom Form Data -->
          <template v-if="!productId">
            <v-row>
              <CustomForm :module="'product'" :module-id="productId" />
            </v-row>
            <br />
          </template>
          <!-- Custom Form Data End -->

          <template>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                placeholder="UPC"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.upc, 'UPC', 1),
                  validateRules.maxLength(productCreate.upc, 'UPC', 100),
                ]"
                v-model.trim="productCreate.upc"
                class="pr-2 width-100"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                placeholder="SKU"
                solo
                flat
                color="cyan"
                :rules="[
                  validateRules.minLength(productCreate.sku, 'SKU', 1),
                  validateRules.maxLength(productCreate.sku, 'SKU', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.sku"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                placeholder="HSN Code"
                solo
                flat
                :rules="[
                  validateRules.minLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    100
                  ),
                ]"
                class="pr-2 width-100"
                v-model.trim="productCreate.hsn_code"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                placeholder="EAN"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.ean, 'EAN', 1),
                  validateRules.maxLength(productCreate.ean, 'EAN', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.ean"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                placeholder="ISBN"
                solo
                flat
                color="cyan"
                class="pr-2 width-100"
                :rules="[
                  validateRules.minLength(productCreate.isbn, 'ISBN', 1),
                  validateRules.maxLength(productCreate.isbn, 'ISBN', 100),
                ]"
                v-model.trim="productCreate.isbn"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                placeholder="Part Number"
                solo
                flat
                color="cyan"
                class="pl-2 width-100"
                :rules="[
                  validateRules.minLength(
                    productCreate.part_number,
                    'Part Number',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.part_number,
                    'Part Number',
                    100
                  ),
                ]"
                v-model.trim="productCreate.part_number"
              ></v-text-field>
            </div>
          </template>
          <div class="d-flex" v-if="false">
            <label class="custom-form-label">Tax Preference</label>
            <v-radio-group
              class="pt-0 mt-0 mb-2"
              dense
              row
              color="cyan"
              v-model.trim="productCreate.taxable"
            >
              <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
              <v-radio
                label="Non-Taxable"
                color="cyan"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>

          <template> </template>
          <template>
            <div
              v-if="false && $hasWarranty && getPermission('warranty:create')"
            >
              <v-checkbox
                class="mt-0 width-100"
                color="cyan"
                v-model.trim="warrantyActive"
                v-on:change="checkWarrrnty($event)"
              >
                <template v-slot:label>
                  <label class="custom-form-label m-0 width-100"
                    >Warranty</label
                  >
                </template>
              </v-checkbox>
              <!-- <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <template v-for="(warranty, index) in productCreate.warranty">
                  <div class="d-flex" :key="index">
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      color="cyan"
                      placeholder="Duration"
                      solo
                      flat
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      color="cyan"
                      hide-details
                      solo
                      flat
                      item-color="cyan"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      placeholder="Type"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                    <div v-if="false" class="pl-2 width-50">
                      <template v-if="index > 0">
                        <v-btn
                          class="mx-2"
                          color="danger"
                          dark
                          fab
                          small
                          v-on:click="removeWarranty(index, warranty.id)"
                        >
                          <v-icon dark> mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn
                          class="mx-2"
                          color="cyan"
                          dark
                          fab
                          small
                          v-on:click="pushWarranty"
                        >
                          <v-icon dark> mdi-plus</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                </template>
              </template> -->
              <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <!-- <template v-for="(warranty, index) in productCreate.warranty"> -->
                <div class="d-flex">
                  <v-text-field
                    dense
                    v-mask="'###'"
                    filled
                    color="cyan"
                    placeholder="Duration"
                    solo
                    flat
                    class="pr-2 width-100"
                    v-model.trim="productCreate.warranty_duration"
                  ></v-text-field>
                  <v-select
                    :items="warrantyDurationTypes"
                    dense
                    filled
                    color="cyan"
                    hide-details
                    solo
                    flat
                    item-color="cyan"
                    class="pl-2 pr-2 width-100"
                    v-model.trim="productCreate.warranty_type"
                    placeholder="Type"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <div v-if="false" class="pl-2 width-50">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="danger"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index, warranty.id)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </div>
                </div>
                <!--  </template> -->
              </template>
            </div>

            <br />
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Product Images
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Images
                    <!--  <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 images are not allowed</span>
                    </v-tooltip> -->
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :file-type="true"
                      :attachments="productCreate.product_images"
                      v-on:file:updated="updateImages"
                      acceptFile="image/png, image/jpeg, image/jpg"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="p-6 font-size-16" v-if="false">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Attachments
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 attachments are not allowed</span>
                    </v-tooltip>
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="productCreate.product_attachments"
                      v-on:file:updated="updateAttachments"
                      :file-type="true"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card
              flat
              class="custom-grey-border remove-border-radius"
              v-if="false"
            >
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Attachments
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <FileUpload v-model="productCreate.files"> </FileUpload>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </perfect-scrollbar>
    </v-col>
    <v-col
      v-if="false"
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageBrand
      :b-dialog="manageBrandDialog"
      :brand="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageManufacturer
      :dialog="manageManufacturerDialog"
      :manufacturer="manufacturerList"
      v-on:close-dialog="manageManufacturerDialog = false"
      v-on:get-product-manufacturer="getOptions"
    ></ManageManufacturer>
    <ManageCategory
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>

    <managePSCategory
      product
      :c-dialog="managePSCategoryDialog"
      :category="pscategoryList"
      v-on:close-dialog="managePSCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></managePSCategory>
    <managefactCategory
      product
      :c-dialog="managefactCategoryDialog"
      :category="factcategoryList"
      v-on:close-dialog="managefactCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></managefactCategory>

    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import managePSCategory from "@/view/pages/partials/Manage-Product-PowerSourceCategory.vue";
import managefactCategory from "@/view/pages/partials/Manage-Product-FactCategory.vue";

import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import DatePicker from "@/view/components/DatePicker";
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";
import moment from "moment";
//import TextInput from "@/view/components/TextInput";

import {
  QUERY,
  PATCH,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
// import { find } from "lodash"; map
import { cloneDeep, filter, find } from "lodash";
// import FileUpload from "@/view/components/FileUpload";
import FileUpload from "@/view/components/app-component/FileInput.vue";

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate,
    DatePicker,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    managePSCategory,
    managefactCategory,
    ManageUnit,
    FileUpload,
    // TextInput,
    CustomForm,
  },
  data() {
    return {
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      proDuplicateId: null,
      timeoutLimit: 500,
      pageLoading: false,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      pscategoryList: new Array(),
      factcategoryList: new Array(),
      manufacturerList: new Array(),
      //  supplierList: new Array(),
      vendorList: [],
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      managePSCategoryDialog: false,
      managefactCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      supplierNameList: [],
      productCreate: {
        seller: 0,
        model_type: null,
        mast_height: null,
        tires: null,
        att: null,
        engine: null,
        power_source: null,
        battery_voltage: null,
        battery_capacity: null,
        factory: null,
        delivery_terms: null,
        warranty_truck: null,
        warranty_li_ion_battery: null,
        country_quote: null,
        model: null,
        model_no: null,
        name: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        ref_number: null,
        item_number: null,
        serial_number: null,
        part_number: null,
        hsn_code: null,
        description: null,
        // selling_price: null,
        // supplier_name: null,
        // purchase_price: null,
        warranty_type: null,
        warranty_duration: 0,
        directory: null,
        supplier: null,
        brand: null,
        barcode: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        project_price: null,
        threshold_price: null,
        /*  quantity:1, */
        commission_rate: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        attachments: [],
        product_images: [],
        product_attachments: [],
        website_url: null,
        purchase_date: null,
        costSupplierData: [
          {
            id: null,
            selling_price: null,
            supplier_name: null,
            purchase_price: null,
          },
        ],
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
        priceList: [
          {
            id: null,
            unit_price: 0,
            selling_price: 0,
            discount_price: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: null,
          },
        ],
        suppliers: [
          {
            id: null,
            uuid: null,
            product_id: null,
            supplier_price: null,
            part_number: null,
          },
        ],
        files: [],
      },

      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;

      // this.productCreate.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        this.productCreate.barcode = param.barcode ? param.barcode : null;

        // const files = map(this.productCreate.files, (row) => {
        //   return { ...row };
        // });

        // if(files.length){
        //   this.productCreate.files = files;
        // }

        this.$nextTick(() => {
          if (param.warranty_type && param.warranty_duration) {
            this.warrantyActive = true;
          }
        });
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }
      },
    },
    /*  productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        console.log(this.productCreate, "this.productCreate");
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }

        this.$nextTick(() => {
          if (
            param.warranty &&
            param.warranty.length &&
            param.warranty[0] &&
            param.warranty[0].value
          ) {
            this.warrantyActive = true;
           
          }
        });
      },
    }, */
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    checkWarrrnty(event) {
      if (!event) {
        this.productCreate.warranty_type = null;
        this.productCreate.warranty_duration = 0;
      } else {
        if (this.updateMode) {
          this.$emit("get:warrantyActive", true);
        }
        this.productCreate.warranty_type = this.productCreate.warranty_type;
        this.productCreate.warranty_duration =
          this.productCreate.warranty_duration;
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.productCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.productCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.productCreate.description &&
        this.productCreate.description.length > 199
      ) {
        e.preventDefault();
      }
    },
    addCostSupplier() {
      this.productCreate.costSupplierData.push({
        id: null,
        selling_price: null,
        supplier_name: null,
        purchase_price: null,
      });
    },
    removeCostSupplier(index) {
      this.productCreate.costSupplierData.splice(index, 1);
    },
    addSupplier() {
      this.productCreate.suppliers.push({
        id: null,
        supplier_id: null,
        part_number: null,
        supplier_price: null,
      });
    },
    // removeSupplier(index, data) {
    //   if (data.id) {
    //     const param = { id: data.id };
    //     RemoveSuppliers(this.uuid, param).then(() => {
    //       this.suppliers.splice(index, 1);
    //     });
    //   } else {
    //     this.suppliers.splice(index, 1);
    //   }
    // },
    removeSupplier(index, data) {
      // console.log(data, "data");

      this.productCreate.suppliers.splice(index, 1);

      const _this = this;

      _this.formLoading = true;
      //  const param = data.id;

      if (this.productId > 0) {
        _this.$store
          .dispatch(PATCH, {
            url: "product/" + this.productId + "/inventory_supplier",
            data: {
              id: data.id,
            },
          })

          .then(() => {})
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
      //const param = data.id;
      /* ApiService.PUT(`product/${param}/inventory_supplier`, )
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			}); */

      // console.log(data, "data");
      // if (data.id) {
      //  // console.log(data.id,"dataid");
      // //	const param = { id: data.id };
      // 	// RemoveSuppliers(this.uuid, param).then(() => {
      // 	// 	this.suppliers.splice(index, 1);
      // 	// });

      // } else {
      // 	this.productCreate.suppliers.splice(index, 1);
      // }
    },
    discountPriceChange(index) {
      if (
        this.productCreate.priceList[index].discount_price >
        this.productCreate.priceList[index].selling_cost
      ) {
        this.productCreate.priceList[index].discount_price =
          this.productCreate.priceList[index].selling_cost;
      }
    },
    discountSelling(index) {
      if (
        this.productCreate.priceList[index].selling_cost <
        this.productCreate.priceList[index].discount_price
      ) {
        this.productCreate.priceList[index].selling_cost =
          this.productCreate.priceList[index].discount_price;
      }
    },
    addPrice() {
      let previous_end_date = null; // moment().format("YYYY-MM-DD");
      if (
        this.productCreate &&
        this.productCreate.priceList &&
        this.productCreate.priceList.length < 1
      ) {
        previous_end_date = moment().format("YYYY-MM-DD");
      }
      this.productCreate.priceList.push({
        id: null,
        unit_price: 0,
        selling_cost: 0,
        discount_price: 0,
        start_date: previous_end_date,
        end_date: null,
      });
    },

    removePrice(index) {
      if (index >= 1) {
        this.productCreate.priceList[index - 1].end_date = null;
      }
      this.productCreate.priceList.splice(index, 1);
    },

    Categorydata() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    BrandData() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.productCreate.images = param;
    },
    updateAttachments(param) {
      this.productCreate.attachments = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          /* if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode &&
            !this.productCreate.barcode
          ) {
            _this.productCreate.barcode = response.data.barcode;
          } */

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
            /*      _this.categoryList.push({
              text: this.productCreateData.category,
            });
            console.log(this.productCreateData,'response.data.category') */
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data?.ps_category
          ) {
            _this.pscategoryList = response.data?.ps_category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data?.fact_category
          ) {
            _this.factcategoryList = response.data?.fact_category;
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          // if (
          //   _this.lodash.isEmpty(response.data) === false &&
          //   response.data.supplier
          // ) {
          //   _this.supplierList = response.data.supplier;
          // }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //  console.log(_this.unitList);
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            /*  if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              _this.productCreate.description =
                _this.barcodeSetting.description;
            } */

            _this.productCreate.serial_number = response.data.serial_number;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  computed: {
    ...mapGetters(["errors", "localDB", ""]),
    supplier_list() {
      return this.localDB("suppliers", []);
    },
    supplierList: () => {
      return function (id) {
        //console.log(id,'id');
        let _vendorList = cloneDeep(this.vendorList);

        // console.log(_vendorList,'_vendorList')
        //  console.log(this.productCreate.suppliers,'111')
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {
            if (
              !find(
                this.productCreate.suppliers,
                (inner_row) => inner_row.supplier_id == row.value
              )
            ) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };
    },

    isStartDateDisable: () => {
      return function (index) {
        if (this.productCreate.priceList.length == index + 1) {
          return false;
        }
        return true;
      };
    },
    end_min_date: () => {
      return function (date, index) {
        let _priceList = cloneDeep(this.productCreate.priceList);
        if (index > 0) {
          const min_end_date = _priceList[index - 1].start_date;
          this.productCreate.priceList[index - 1].end_date = date;
          return min_end_date;
        }
        return moment().format("YYYY-MM-DD");
      };
    },

    //   isDisable() {
    // 	const exists =  this.priceList.find((row) => {
    // 		return !row.start_date;
    // 	});
    // 	return exists ? true : false;
    // },
  },
  mounted() {
    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      this.vendorList = this.$store.state.config.localDB["data"]["suppliers"]; //this.localDB("suppliers", []);
      //console.log(this.$store.state.config.localDB['data']['suppliers'],"2222");
    });

    this.getOptions();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
